<template>
    <div class="wages">
        <div class="table-title">
            <p>工资表</p>
            <span>
                <button v-if="btnP.upload" @click="onTemplateUrl()">
                    下载模版
                </button>
                <button
                    style="margin-left: 16px"
                    @click="onUpload()"
                    v-if="btnP.upload"
                >
                    上传工资表
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column label="年/月" width="75" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.month || '- -' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="Excel名称" width="185">
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.attachmentName || '- -' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="发放金额" show-overflow-tooltip width="90">
                <template slot-scope="scope">
                    <span>
                        {{ getAmount(scope.row.wagesDetailAmountBO) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="已发送" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span>
                        {{
                            getStatusTotal2(
                                2,
                                scope.row.wagesDetailStatusBOList
                            )
                        }}/{{ getTotal(scope.row.wagesDetailStatusBOList) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="已查看" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span>
                        {{
                            getStatusTotal2(
                                3,
                                scope.row.wagesDetailStatusBOList
                            )
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="已确认" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span>
                        {{
                            getStatusTotal2(
                                4,
                                scope.row.wagesDetailStatusBOList
                            )
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="反馈人数" show-overflow-tooltip width="70">
                <template slot-scope="scope">
                    <span>
                        {{
                            getStatusTotal2(
                                4,
                                scope.row.wagesDetailStatusBOList
                            )
                        }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="发送时间"
                show-overflow-tooltip
                prop="sendTime"
                width="160"
            >
                <template slot-scope="scope">
                    <span
                        v-if="
                            getStatusTotal(
                                2,
                                scope.row.wagesDetailStatusBOList
                            ) == 0
                        "
                    >
                        待发送
                    </span>
                    <span v-else>
                        {{
                            scope.row.wagesDetailSendBO
                                ? scope.row.wagesDetailSendBO.sendTime
                                : ''
                        }}</span
                    >
                </template>
            </el-table-column>

            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.details"
                        type="text"
                        style="color: #2370eb"
                        @click="onDetail(scope.row)"
                        size="small"
                        >详情</el-button
                    >
                    <el-button
                        v-if="
                            (scope.row.stusus == 6 || !scope.row.sendTime) &&
                            btnP.send
                        "
                        type="text"
                        style="color: #2370eb"
                        size="small"
                        @click="onDialog(1, scope.row)"
                        >发送</el-button
                    >
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            <i
                                class="iconfont icon-gengduo"
                                style="
                                    color: #2370eb;
                                    font-weight: 600;
                                    margin-left: 10px;
                                "
                            ></i>
                        </span>
                        <el-dropdown-menu
                            slot="dropdown"
                            style="margin-left: 10px; text-align: center"
                        >
                            <el-dropdown-item>
                                <el-button
                                    @click="
                                        onDownload(
                                            scope.row.resultAttachmentUrl
                                        )
                                    "
                                    style="color: #2370eb"
                                    type="text"
                                >
                                    下载导入结果
                                </el-button>
                            </el-dropdown-item>

                            <el-dropdown-item>
                                <el-button
                                    v-if="btnP.delete"
                                    style="color: #2370eb"
                                    type="text"
                                    @click="onDialog(2, scope.row)"
                                >
                                    删除
                                </el-button>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 标签搜索 -->
        <el-dialog
            :visible.sync="dialogUpload"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    上传工资表
                </div>
            </template>
            <Upload @close="handleClose" />
        </el-dialog>
        <!-- 删除/发送 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ dialogTitle }}
                </div>
            </template>
            <div class="dialog_info">
                <p>
                    <i
                        class="el-icon-warning-outline"
                        style="
                            color: #f5a623;
                            font-size: 50px;
                            margin-bottom: 15px;
                        "
                    ></i>
                </p>

                <p>{{ dialogText }}</p>
            </div>
            <div slot="footer" class="bottom-but">
                <el-button
                    @click="handleClose"
                    round
                    style="padding: 7px 20px"
                    size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="
                        margin-right: 15px;
                        background: #2370eb;
                        padding: 7px 20px;
                    "
                    round
                    @click="onSubmit()"
                    size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Upload from './upload.vue';
import { wagesList, wagesDeletee, wagesSend } from '@/api/wages/wages';
export default {
    components: {
        Upload,
    },
    data() {
        return {
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            dialogUpload: false,
            dialog: false,
            dialogText: '',
            dialogTitle: '',
            btnP: {},
            dialogType: 0,
            dialogData: {},
            isPage: false,
        };
    },
    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param;
            if (this.searchVal.departmentId) {
                this.getUserList(this.searchVal.departmentId);
            }

            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
            if (this.$route.query.name) {
                this.searchVal.name = this.$route.query.name;
            }
        }

        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;

            this.getData();
        },
        getData() {
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            wagesList(data).then((res) => {
                console.log(res);
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        getAmount(row) {
            let nub = 0;
            for (let i in row) {
                if (i != 'taxTotalAmount' && i != 'leaveTotalAmount') {
                    nub += row[i];
                }

                if (i == 'taxTotalAmount') {
                    nub -= row[i];
                }
                if (i == 'leaveTotalAmount') {
                    nub -= row[i];
                }
            }
            return nub.toFixed(2);
        },
        getTotal(row) {
            let total = 0;
            for (let item in row) {
                total += Number(row[item].statusTotal);
            }
            return total;
        },
        getStatusTotal(i, row) {
            let nub = 0;
            let total = 0;
            for (let item in row) {
                if (i == 2) {
                    if (
                        row[item].status == 2 ||
                        row[item].status == 3 ||
                        row[item].status == 4 ||
                        row[item].status == 5
                    ) {
                        nub = row[item].statusTotal;
                    }
                } else {
                    if (i == row[item].status) {
                        nub = row[item].statusTotal;
                    }
                }
                total += Number(row[item].statusTotal);
            }
            return nub.toFixed(2);
        },
        getStatusTotal2(i, row) {
            let nub = 0;
            let total = 0;
            for (let item in row) {
                if (i == 2) {
                    if (
                        row[item].status == 2 ||
                        row[item].status == 3 ||
                        row[item].status == 4 ||
                        row[item].status == 5
                    ) {
                        nub = row[item].statusTotal;
                    }
                } else {
                    if (i == row[item].status) {
                        nub = row[item].statusTotal;
                    }
                }
                total += Number(row[item].statusTotal);
            }
            return nub;
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 下载模板
        onTemplateUrl() {
            window.open(
                this.$url.templateUrl() + '/excel/model/wages.xlsx',
                '_blank'
            );
        },
        // 上传工资
        onUpload() {
            this.dialogUpload = true;
        },
        handleClose() {
            this.dialogUpload = false;
            this.dialog = false;
            this.getBtn();
        },
        // 弹窗提示
        onDialog(i, row) {
            this.dialogType = i;
            this.dialogData = row;
            if (i == 1) {
                this.dialogText = '即将发送当前工资表，是否确认？';
                this.dialogTitle = '发送工资表';
            }
            if (i == 2) {
                this.dialogText =
                    '确认删除此工资条？删除后员工将无法查看已收到的工资条';
                this.dialogTitle = '删除工资表';
            }
            this.data = row;
            this.dialog = true;
        },
        onSubmit() {
            let data = {
                param: {},
            };
            if (this.dialogType == 1) {
                data.param.wagesId = this.dialogData.id;
                wagesSend(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
            if (this.dialogType == 2) {
                data.param.id = this.dialogData.id;
                wagesDeletee(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.handleClose();
                    }
                });
            }
        },
        // 详情
        onDetail(row) {
            this.$router.push({
                path: '/wagesDetail',
                query: {
                    id: row.id,
                },
            });
        },
        onDownload(row) {
            window.open(this.$url.templateUrl() + '/excel/' + row, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
.wages {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
        button {
            font-size: 12px;
            width: 88px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            color: #333333;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }

    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.dialog_info {
    width: 80%;
    text-align: center;
    margin: 25px auto 80px;
    font-size: 14px;
    color: #333333;

    p + p {
        margin-top: 16px;
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/ .el-dialog__footer {
    padding: 0;
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
</style>
