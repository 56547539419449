import http from '@/utils/http.js'

// 工资条列表
export function wagesList(data) {
    return http({
        url: "/jasoboss/wages/list",
        method: "post",
        data
    })
}
// 导入工资条
export function wagesImport(data) {
    return http({
        url: "/jasoboss/wages/import",
        method: "post",
        data
    })
}
// 工资条明细（列表）
export function wagesDetailList(data) {
    return http({
        url: "/jasoboss/wages/detail/list",
        method: "post",
        data
    })
}
// 工资条明细（单个）
export function wagesDetailGet(data) {
    return http({
        url: "/jasoboss/wages/detail/get",
        method: "post",
        data
    })
}
// 工资条明细修改
export function wagesDetailUpdate(data) {
    return http({
        url: "/jasoboss/wages/detail/update",
        method: "post",
        data
    })
}
// 工资条删除
export function wagesDeletee(data) {
    return http({
        url: "/jasoboss/wages/delete",
        method: "post",
        data
    })
}
// 工资条明细全部重发
export function wagesSend(data) {
    return http({
        url: "/jasoboss/wages/detail/all/send",
        method: "post",
        data
    })
}
// 工资条明细全部撤回
export function wagesAllRemove(data) {
    return http({
        url: "/jasoboss/wages/detail/all/remove",
        method: "post",
        data
    })
}
// 工资条明细(单个)
export function wagesGet(data) {
    return http({
        url: "/jasoboss/wages/detail/get",
        method: "post",
        data
    })
}
// 工资条明细修改
export function wagesUpdate(data) {
    return http({
        url: "/jasoboss/wages/detail/update",
        method: "post",
        data
    })
}
// 反馈列表
export function feedbackList(data) {
    return http({
        url: "/jasoboss/wages/detail/feedback/list",
        method: "post",
        data
    })
}
// 反馈添加
export function feedbackAdd(data) {
    return http({
        url: "/jasoboss/wages/detail/feedback/add",
        method: "post",
        data
    })
}