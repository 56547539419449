<template>
    <div class="upload">
        <div class="content">
            <p>
                <span style="margin-right: 20px"> <i style="color: #d0021b">*</i> 选择时间</span>
                <el-date-picker
                    type="month"
                    placeholder="选择月"
                    v-model="month"
                    size="small"
                    style="width: 270px"
                    value-format="yyyy-MM"
                >
                </el-date-picker>
            </p>
            <p>
                <el-upload
                    class="upload-demo"
                    :action="action"
                    :on-success="onExcel"
                    :before-upload="beforeAvatarUploads"
                    drag
                    :limit="1"
                    ref="businessLicense"
                    accept=".doc,.docx,.xls,.xlsx,"
                    :file-list="businessLicense"
                    :before-remove="removeExcel"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        将文件拖拽到此区域 <br /><el-button
                            size="mini"
                            style="
                                background: #2370eb;
                                color: #fff;
                                border-color: #2370eb;
                                margin-top: 10px;
                            "
                        >
                            点击上传
                        </el-button>
                    </div>
                </el-upload>
            </p>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: auto"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff; margin-right: 30px"
                size="mini"
                @click="onSubmit"
                >上传</el-button
            >
        </div>
    </div>
</template>
<script>
import { wagesImport } from '@/api/wages/wages';
export default {
    data() {
        return {
            month: [],
            action: this.$url.url() + '/file/local/upload',
            fileName: '',
            fileShowName: '',
            businessLicense: [],
        };
    },
    methods: {
        // 上传
        onExcel(response, file) {
            console.log(file);
            this.fileShowName = file.name;
            this.fileName = '';
            if (response.code == 200) {
                this.fileName = response.data.newFileName;
                this.$message.success('操作成功');
            } else {
                this.$message.error('操作失败');
            }
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 20MB!');
            }
            return isLt2M;
        },
        removeExcel() {
            this.fileName = '';
            this.fileShowName = '';
            this.businessLicense = [];
        },
        onSubmit() {
            if (!this.month) {
                return this.$message.error('请选择时间');
            }
            if (this.fileName == '') {
                return this.$message.error('请上传文件');
            }
            let data = {
                param: {
                    month: this.month,
                    fileName: this.fileName,
                    fileShowName: this.fileShowName,
                },
            };
            wagesImport(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('上传成功');
                }
                this.onClose();
            });
        },
        onClose() {
            this.$emit('close');
            this.businessLicense = [];
            this.month = '';
        },
    },
};
</script>
<style lang="scss" scoped>
.upload {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
        height: 300px;
        padding: 32px 0;
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 20px;
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
